import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Nav = () => {

  return (
      <div className="menu-nav">
        <ul>
          <li className="actif"><Link className="home" to="/">Adresse</Link></li>
          <li><Link className="commandes" to="/">Durée</Link></li>
          <li><Link className="profil" to="/">Faites votre choix</Link></li>
          <li><Link className="contact" to="/">Type de soin</Link></li>
          <li><Link className="contact" to="/">Paiement</Link></li>
        </ul>
      </div>
  );
}

export default Nav;
