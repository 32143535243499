// ChooseLocation.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header'
import Nav from './Nav'
import { useLoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';

const Profile = () => {

  const navigate = useNavigate();


  return (
    <>
    <Header />

    <div className="container container-profile">
      <div className="name">John Appleseed</div>
      <div className="lieu">Geneva</div>
      <div className="adress">
        <h3>Adresse</h3>
        <div className="rue">Rue de Geneva 21<br /> 1205 Geneva<br /> +41 123 4555 6334</div>
        <a href="#" className="edit">Edit</a>
      </div>
      <div className="list">
        <ul>
          <li>
            <a href="#">Modifier mon profil</a>
          </li>
          <li>
            <a href="#">Carte de paiement</a>
          </li>
          <li>
            <a href="#">CGU et Politique de Confidentialité </a>
          </li>
          <li className="logout">
            <a href="#">Déconnexion</a>
          </li>
        </ul>
      </div>
    </div>
    </>
  );
}

export default Profile;
