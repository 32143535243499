import React, { useEffect, useState } from 'react'

const Header = () => {

  return (
      <div className="header">
        <a href="#" className="logo">Flyspa</a>
      </div>
  );
}

export default Header;
