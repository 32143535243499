import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import cloche from "../../assets/images/cloche.svg";
import croix from "../../assets/images/btn-fermer.svg";
import profil from "../../assets/images/profil.png";
import flag from "../../assets/images/flags.png";
import star from "../../assets/images/star.png";
import mahrez from "../../assets/images/mahrez.png";
import ReactPaginate from "react-paginate";
import { axiosPrivate } from "../../api/axios";
import { endpoints } from "../../utils/utils";

const customStyles = {
  content: {
    width: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const ListesUser = ({ users, update }) => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const PER_PAGE = 10;
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axiosPrivate.post(endpoints.invite, {
        firstname: nom,
        lastname: prenom,
        email,
        phone: telephone,
      });

      console.log("Réponse du serveur:", response.data);

      update();
      // Réinitialiser les champs du formulaire après soumission si nécessaire
      setNom("");
      setPrenom("");
      setEmail("");
      setTelephone("");
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire:", error);
      // Gérer les erreurs, par exemple afficher un message à l'utilisateur
    }
  };

  const currentPageData = users?.map((item) => (
    <tr key={item?.id}>
      <td>
        <div className="photo">
          {/*<img src={item?.photo ? item?.photo : profil} alt="Profil" />*/}
          <img
            src={
              item?.photo
                ? item?.photo.startsWith("baseUrl") ||
                  item?.photo.startsWith("https://lh3.googleusercontent.com/")
                  ? item?.photo
                  : `${"baseUrl"}${item?.photo.substring(
                      item?.photo.indexOf("/images/")
                    )}`
                : profil
            }
            alt="Profil"
          />
        </div>{" "}
        {item?.nom} {item?.prenom}
      </td>

      <td>{item?.email}</td>
      <td>{item?.roles[0]}</td>
      <td className="edit-content edit-fan">
        <div className="action">
          <span>Edit</span>
          <div className="">
            <a className="edit" href="#0" onClick={() => openModal(item)}>
              Modifier
            </a>
          </div>
        </div>
      </td>
    </tr>
  ));

  function openModal() {
    setIsOpen(true);
    console.log(users);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="content-page">
        <div className="header">
          <h1>Gerstion Users</h1>
          <div className="notifications">
            <div className="icons">
              <img src={cloche} alt="Notifications" />
            </div>
          </div>
        </div>
        <div className="btn-add-form bloc-header-user">
          <label>Gerstion Users</label>
          <select>
            <option value="">Filtre by</option>
            <option value="Options 1">Options 1</option>
          </select>
          <input type="text" name="" id="" placeholder="Search" />
        </div>

        <div className="content-listes">
          <table className="tableau-list">
            <tbody>{currentPageData}</tbody>
          </table>
        </div>

        <a href="#0" className="add-user" onClick={openModal}>
          Ajouter un Admin
        </a>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal">
          <div className="headerModal">
            <a onClick={closeModal}>
              <img src={croix} alt="Fermer" />
            </a>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-text name">
              <label>Nom</label>
              <input
                type="text"
                placeholder="Nom"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </div>
            <div className="form-text name">
              <label>Prénom</label>
              <input
                type="text"
                placeholder="Prénom"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
              />
            </div>
            <div className="form-text email">
              <label>Email</label>
              <input
                type="email"
                placeholder="exemple@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-text tel">
              <label>Téléphone</label>
              <input
                type="text"
                placeholder="+293 33 03 292"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </div>
            <div className="form-button">
              <button type="submit">Inviter</button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ListesUser;
