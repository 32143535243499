import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from './Nav';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51IYwAqDh1V63ycuimBrVJgxaQkDKtUdxkpFmYv38yV8qmiCEeGCKTvlQi7cIAjepUBVaY7Tyx4xSAw8qjBdbB1ze00OanEXi4X'); // Remplacez par votre clé publique Stripe

function PaymentForm({ totalPriceCHF }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();
  const { address, numberOfPeople, duration, selectedTherapist, selectedDate, selectedService } = location.state || {};

  const therapist = {
    name: 'Thérapeute 1',
    image: 'https://example.com/image1.jpg'
  };

  const serviceDetails = {
    title: 'Massage relaxant',
    priceCHF: totalPriceCHF // Prix total en francs suisses (passé en prop)
  };

  const [message, setMessage] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!termsAccepted) {
      alert('Vous devez accepter les conditions générales.');
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    setPaymentProcessing(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setPaymentError(error.message);
      setPaymentProcessing(false);
      return;
    }

    console.log('Paiement soumis avec succès ! Redirection vers la page de confirmation.');
    setTimeout(() => {
      navigate('/confirmation');
    }, 2000);
  };

  return (
    <>
      <Nav />
      <div className="container container-payment">
        <div className="payment-summary">
          <h2>Récapitulatif</h2>
          <div className="therapist-info">
            <img src={therapist.image} alt={therapist.name} className="therapist-image" />
            <div>
              <h3>{therapist.name}</h3>
              <p>Type de thérapie: {serviceDetails.title}</p>
              <p>Date: {selectedDate}</p>
              <p>Prix: {serviceDetails.priceCHF} CHF</p> {/* Affichage du prix en CHF */}
              <p>Adresse: {address.street} {address.city} {address.postcode} </p>
            </div>
          </div>
        </div>
        <div className="payment-form">
          <h2>Finaliser le paiement</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="promoCode">Code promo</label>
              <input
                type="text"
                id="promoCode"
                className="form-control"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="total">Total (TVA incluse)</label>
              <input
                type="text"
                id="total"
                className="form-control"
                value={`${serviceDetails.priceCHF} CHF`} 
                readOnly
              />
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                id="terms"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              <label htmlFor="terms">J'accepte les conditions générales</label>
            </div>
            <div className="form-group">
              <label htmlFor="cardElement">Informations de la carte</label>
              <CardElement id="cardElement" className="form-control" />
            </div>
            {paymentError && <div className="error-message">{paymentError}</div>}
            <button type="submit" className="btn btn-primary" disabled={paymentProcessing}>
              {paymentProcessing ? 'Traitement...' : `Payer maintenant ${serviceDetails.priceCHF} CHF`} {/* Bouton de paiement en CHF */}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

function Payment() {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm totalPriceCHF={120} /> {/* Prix total en CHF (exemple) */}
    </Elements>
  );
}

export default Payment;
