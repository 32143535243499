// ChooseLocation.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header'
import Nav from './Nav'
import { useLoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';

const EditProfile = () => {

  const navigate = useNavigate();


  return (
    <>
    <Header />

    <div className="container container-profile">
      <div className="name">Modifier mon profil</div>
      <form className="form">
        <div className="sexe">
          <div className="choix">
            <input type="radio" name="sexe" id="madame" />
            <label for="madame">Madame</label>
          </div>
          <div className="choix">
            <input type="radio" name="sexe" id="monsieur" />
            <label for="monsieur">Monsieur</label>
          </div>
        </div>
        <div className="form-group">
          <label>Prénom</label>
          <input type="text" placeholder="John" />
        </div>
        <div className="form-group">
          <label>Nom</label>
          <input type="text" placeholder="Appleseed" />
        </div>
        <div className="form-group">
          <label>Sexe</label>
          <select>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="form-group">
          <label>Numéro de téléphone portable</label>
          <input type="text" placeholder="+41 123 4555 6334" />
        </div>
        <div className="form-group">
          <label>Adresse email</label>
          <input type="email" placeholder="john.appleseed@gmail.com" />
        </div>
        <div className="form-group">
          <label>Nouveau mot de passe</label>
          <input type="password" placeholder="password" />
        </div>
        <div className="form-group">
          <label>Date de naissance </label>
          <input type="date" placeholder="01/01/1971" />
        </div>
        <div className="form-check">
          <input type="checkbox" name="cgu" id="cgu" />
          <label for="cgu">Accepter les CGU et la Politique de Confidentialité *</label>
        </div>
        <div className="form-submit">
          <input type="submit" name="" id="" value="Enregistrer" />
        </div>
      </form>
      <a href="#" className="btn-remove-user">Supprimer le compte</a>
    </div>
    </>
  );
}

export default EditProfile;
