// firebase.js
import {initializeApp} from '@firebase/app';
import { getMessaging, getToken } from '@firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyALmxudQ_lRaaT4VWarFuGPwR2haylu-l4",
    authDomain: "jololel---brmx.firebaseapp.com",
    projectId: "jololel---brmx",
    storageBucket: "jololel---brmx.appspot.com",
    messagingSenderId: "875436301000",
    appId: "1:875436301000:web:309aadff4efae1ed1de3fd"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const messaging = getMessaging(firebaseApp);
export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission);

    if (permission === "granted") {
        const token = await getToken (messaging, {
        vapidKey:
        "BC2psvelxDdF7rrjyxkCp7ehgRa_zrZNl9USY178S83jN61Zyh58ONxvG2AayVP5p5NGYt3ZrCLAojcmOBM-6Xc",
        });
        console.log(token);
    }
    };