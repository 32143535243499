// ChooseLocation.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header'
import Nav from './Nav'
import { useLoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';

const EditAdresse = () => {

  const navigate = useNavigate();


  return (
    <>
    <Header />

    <div className="container container-profile">
      <div className="name">Modifier adresse</div>
      <form className="form">
        <div className="form-group">
          <label>Rue</label>
          <input type="text" placeholder="Rue de Geneva 21" />
        </div>
        <div className="form-group">
          <label>Code postal</label>
          <input type="text" placeholder="1242" />
        </div>
        <div className="form-group">
          <label>Ville</label>
          <input type="text" placeholder="Geneva" />
        </div>
        <div className="form-submit">
          <input type="submit" name="" id="" value="Enregistrer" />
        </div>
      </form>
    </div>
    </>
  );
}

export default EditAdresse;
