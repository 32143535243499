import React, { Fragment, useEffect, useState } from "react";
import Aside from "../Aside";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { endpoints } from "../../utils/utils";
import Swal from "sweetalert2";
import ListesUser from "./ListesUser";

const User = () => {
  const [users, setUsers] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getAdminUser);
        console.log(response.data.users);
        isMounted && setUsers(JSON.parse(response.data.users));
      } catch (err) {
        console.error(err);
        //navigate('/login', { state: { from: location }, replace: true });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenu au niveau du serveur",
        });
      }
    };
    getUsers();
    return () => {
      isMounted = false;
      controller.abort();
    };
  },[]);
  const update = () => {
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getAdminUser);
        console.log(response.data);
        /* isMounted && */ setUsers(JSON.parse(response.data.users));
      } catch (err) {
        console.error(err);
        //navigate('/login', { state: { from: location }, replace: true });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenu au niveau du serveur",
        });
      }
    };
    getUsers();
  };
  return (
    <Fragment>
      <Aside />
      <ListesUser users={users} update={update} />
    </Fragment>
  );
};

export default User;
