import React, { useEffect, useState } from 'react'

const MenuHeader = () => {

  return (
      <div className="menu-header">
        <a href="#" className="logo">Flyspa</a>
        <div className="menu">
          <div className="menu-left">
            <ul>
              <li><a href="#">Contact</a></li>
            </ul>
          </div>
          <div className="menu-right">
            <ul>
              <li><a href="#" className="notif"><span>3</span></a></li>
              <li><a href="#" className="account">SQ</a></li>
              <li><a href="#" className="burger"></a></li>
            </ul>
          </div>
        </div>
      </div>
  );
}

export default MenuHeader;
