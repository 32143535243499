// ChooseLocation.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header'
import Nav from './Nav'
import { useLoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';

const libraries = ['places'];
const ChooseLocation = () => {

  const navigate = useNavigate();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514',
    libraries,
  });

  const [address, setAddress] = useState({
    street: '',
    city: '',
    postcode: '',
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    // Save data to state or context API or send to backend

    // Redirect to next step
    navigate('/choose-duration', {state:{address}});
  };

  const handlePlaceSelect = (place) => {
    const addressComponents = place.address_components;
    const newAddress = {
      street: '',
      city: '',
      postcode: '',
    };

    for (const component of addressComponents) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          newAddress.street = `${component.long_name} ${newAddress.street}`;
          break;
        case 'route':
          newAddress.street = `${newAddress.street} ${component.long_name}`;
          break;
        case 'locality':
          newAddress.city = component.long_name;
          break;
        case 'postal_code':
          newAddress.postcode = component.long_name;
          break;
        default:
          break;
      }
    }

    setAddress(newAddress);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <>
    <Header />
    <Nav/>

    <div className="container container-location">
      <h2>Où souhaitez-vous le soin ?</h2>
      <div className="form-group">
      <label htmlFor="street">Saisissez une adresse</label>
      <Autocomplete
        apiKey="AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514"
        onPlaceSelected={handlePlaceSelect}
        options={{
          types: ['address'],
          componentRestrictions: { country: 'CH' },
        }}
        style={{ width: '100%', padding: '0 15px', height:'60px', border:'0', borderRadius:'20px', boxShadow:'0px 8px 50px 0px rgba(0, 0, 0, 0.06)' }}
      />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="street">Rue</label>
          <input
            type="text"
            id="street"
            className="form-control"
            value={address.street}
            onChange={(e) => setAddress({ ...address, street: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="postalCode">Code postal</label>
          <input
            type="text"
            id="postalCode"
            className="form-control"
            value={address.postcode}
            onChange={(e) => setAddress({ ...address, postcode: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">Ville</label>
          <input
            type="text"
            id="city"
            className="form-control"
            value={address.city}
            onChange={(e) => setAddress({ ...address, city: e.target.value })}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Suivant
        </button>
      </form>
    </div>
    </>
  );
}

export default ChooseLocation;
