// Home.js
import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="page-home">
      <div className="container">
        <h1>Prêt pour votre <br />prochain massage?</h1>
        <Link to="/choose-location">
          <button>Choisissez votre flypro</button>
        </Link>
      </div>
    </div>
  );
}

export default Home;
