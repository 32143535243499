import React from 'react';
import { createRoot } from 'react-dom/client'; // Mettez à jour l'importation
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from "@react-oauth/google"
import { register } from './utils/serviceWorker';
// Utilisez createRoot pour rendre votre application
const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
      <GoogleOAuthProvider clientId='472957532659-6m2a6or1h0vlh49ovbgd63avhs7t4h3d.apps.googleusercontent.com'>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
        </GoogleOAuthProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
register();