
import React from 'react';
import InscriptionForm from './Inscription';
const Register = () => {
 
  return (
    
        <InscriptionForm />
     
  );
};

export default Register;
