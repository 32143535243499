// ChooseLocation.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header'

const VerificationNumero = () => {

  return (
    <>
    <Header />

    <div className="container container-contact">
      <h2>Vérification du numéro de téléphone</h2>
      <p>Afin de confirmer votre numéro de mobile un code vous à été envoyé.<br />Veuillez saisir le code reçu par SMS</p>
      <form>
        <div className="form-text">
          <label>Code</label>
          <input type="text" name="" id="" />
        </div>
        <div className="form-submit">
          <input type="submit" value="Vérifier" />
        </div>
      </form>
    </div>
    </>
  );
}

export default VerificationNumero;
